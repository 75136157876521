import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Image, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./Quad.css";

function Results({ resultData, onResultClick, imageUrl, onUserComment }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [satImageSrc, setSatImageSrc] = useState("");
  const [samImageSrc, setSamImageSrc] = useState("");
  const [propertyImageSrc, setPropertyImageSrc] = useState("");

  const handleClose = () => {
    if (onUserComment) {
      onUserComment(comment); // Pass the comment back to FootPrintMap
    }
    setShow(false); // Close the modal
  };
  const [formattedData, setFormattedData] = useState({});
  const handleShow = () => setShow(true);
  const [navigate, setNavigate] = useState(false);

  const [comment, setComment] = useState("");

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get("user");

        setName(data.name);
      } catch (e) {
        setNavigate(true);
      }
    })();
  }, []);

  const handleWrite = async () => {
    console.log("ResultData: ", resultData);

    try {
      let result = {
        address: "",
        county: "",
        latitude: 0,
        longitude: 0,
        distance: 0,
        area: 0,
        heading: 0,
        pitch: 0,
        stories: 0,
        top: 0,
        bottom: 0,
        total_area: 0,
        height: 0,
      };

      const data = resultData.Results;

      // Map each property directly
      if (data.Address) result.address = data.Address;
      if (data.County) result.county = data.County;
      if (data.Latitude) result.latitude = data.Latitude;
      if (data.Longitude) result.longitude = data.Longitude;
      if (data["Camera Distance"]) result.distance = data["Camera Distance"];
      if (data.Area) result.area = data.Area;
      if (data.Heading) result.heading = data.Heading;
      if (data.Pitch) result.pitch = data.Pitch;
      if (data.Stories) result.stories = data.Stories;
      if (data.Top) result.top = data.Top;
      if (data.Bottom) result.bottom = data.Bottom;
      if (data["Total Area"]) result.total_area = data["Total Area"];
      if (data["OneGeo Height"]) result.onegeo_height = data["OneGeo Height"];
      if (data["Height"]) result.height = data.Height;
      
      console.log("Result Array: ", result);

      const response = await axios.post(
        "https://spe.orangelaboratory.com/run_result/",
        result
      );
      console.log("Task triggered:", response);
    } catch (error) {
      console.error("Error triggering task:", error);
    }
  };

  const exportPDF = () => {
    const input = document.getElementById("print");
    html2canvas(input, {
      scale: 1,
      width: input.width,
      height: input.height,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("property.pdf");
    });
  };

  const handleImageUpdate = () => {
    const timestamp = new Date().getTime();
    console.log("Calling for image update");  
    setImageSrc(`https://spe.orangelaboratory.com/foot/image?timestamp=${timestamp}`);
    console.log("Image updated");
  };

  const handleSatImageUpdate = () => {
    const timestamp = new Date().getTime();
    console.log("Calling for image update");  
    setSatImageSrc(`https://spe.orangelaboratory.com/foot/satimage?timestamp=${timestamp}`);
    console.log("Image updated");
  };

  const handlePropertyImageUpdate = () => {
    const timestamp = new Date().getTime();
    console.log("Calling for image update");  
    setPropertyImageSrc(`https://spe.orangelaboratory.com/foot/propimage?timestamp=${timestamp}`);
    console.log("Image updated");
  };

  const handleSamImageUpdate = () => {
    const timestamp = new Date().getTime();
    console.log("Calling for image update");  
    setSamImageSrc(`https://spe.orangelaboratory.com/foot/windows?timestamp=${timestamp}`);
    console.log("Image updated");
  };

  useEffect(() => {
    (async () => {
      try {
        handleImageUpdate();
        handleSatImageUpdate();
        handlePropertyImageUpdate();
        handleSamImageUpdate();
        console.log("Images updated in useEffect");
      } catch (e) {
        console.error("Error loading images:", e);
      }
    })();
  }, []);

  const exportToExcel = async (resultData) => {
    console.log("Export Function called");

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data Sheet");

    // Define columns based on your JSON structure
    worksheet.columns = [
      { header: "Address", key: "Address", width: 30 },
      { header: "County", key: "County", width: 15 },
      { header: "Latitude", key: "Latitude", width: 15 },
      { header: "Longitude", key: "Longitude", width: 15 },
      { header: "Camera Distance", key: "Camera Distance", width: 20 },
      { header: "Area", key: "Area", width: 20 },
      { header: "Height", key: "Height", width: 15 },
      { header: "Stories", key: "Stories", width: 10 },
      { header: "Top", key: "Top", width: 10 },
      { header: "Bottom", key: "Bottom", width: 10 },
      { header: "Comment", key: "comment", width: 255 },
    ];
    console.log(resultData);
    // Add rows
    resultData.Results.forEach((item) => {
      // Handle the 'Area' object specially if it exists
      if (item.Area && typeof item.Area === "object") {
        item.Area = item.Area["0"] || null;
      }
      const row = { ...item, comment: comment };
      worksheet.addRow(row);
    });

    // Write the workbook to a blob
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Use FileSaver to download the file
    saveAs(blob, "property_data.xlsx");
    console.log("File downloaded");
  };

  const handleButtonClick = async () => {
    try {
      await onResultClick();
      handleShow();
    } catch (error) {
      console.error("Error triggering Task: ", error);
    }
  };

  if (navigate) {
    return <Navigate to="/login" />;
  }

  const renderJSON = (data) => {
    if (!data) {
      return <p>Loading Results...</p>;
    }

    const formatValue = (key, value) => {
      // Skip invalid values
      if (value === null || value === undefined || value === 0 || value === "Unknown") {
        return null;
      }
      if (key === "Area") {
        return Math.round(value * 10.764).toString();
      } else if (Array.isArray(value)) {
        const formattedArrayValue = formatValue(key, value[0]);
        return formattedArrayValue === null ? null : formattedArrayValue;
      } else if (typeof value === "number") {
        return value.toFixed(1);
      } else if (typeof value === "object" && value !== null) {
        return renderJSON(value);
      }
      return value.toString();
    };

    // Add 'Total Area' to the data if both 'Area' and 'Stories' are present
    if (data.hasOwnProperty("Area") && data.hasOwnProperty("Stories")) {
      const areaValue = Math.round(data.Area * 10.764);
      const storiesValue = data.Stories;
      data["Total Area"] = (areaValue * storiesValue).toFixed(1);
    }

    return (
      <div style={{ fontFamily: "monospace" }}>
        {Object.entries(data)
          .map(([key, value], index) => {
            const formattedValue = formatValue(key, value);
            return formattedValue === null ? null : (
              <div key={index}>
                <strong>{key}</strong>: {formattedValue}
              </div>
            );
          })
          .filter(Boolean)}
      </div>
    );
  };

  return (
    <>
      <Button
        variant="primary"
        style={{
          fontSize: "24px",
          marginRight: "2px",
          backgroundColor: "#F0512B",
          borderColor: "#F0512B",
        }}
        onClick={handleButtonClick}
      >
        Results
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        id="print"
      >
        <Modal.Header closeButton>
          <Modal.Title>Building Property Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-5">
            <strong>User:</strong> {name && name}{" "}
          </p>
          {renderJSON(resultData)}
          <Form>
            <Form.Group className="mb-3 mt-3" controlId="commentInput">
              <Form.Label>
                <strong>Please enter comments:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Comment on the results"
                value={comment}
                onChange={handleCommentChange}
              />
            </Form.Group>
          </Form>
          <div className="grid-container">
            <Image
              src={imageSrc}
              alt="Road Image"
              fluid
              className="mb-3 rounded grid-image"
              onError={(e) => console.error("Error loading image:", e.target.src)}
            />
            <Image
              src={satImageSrc}
              alt="Sat Image"
              fluid
              className="mb-3 rounded grid-image"
              onError={(e) => console.error("Error loading image:", e.target.src)}
            />
            <Image
              src={propertyImageSrc}
              alt="Result Image"
              fluid
              className="mb-3 rounded grid-image"
              onError={(e) => console.error("Error loading image:", e.target.src)}
            />
            <Image
              src={samImageSrc}
              alt="AI Image"
              fluid
              className="mb-3 rounded grid-image"
              onError={(e) => console.error("Error loading image:", e.target.src)}
            />
            {/* <Image
              src="/ai/final_overlay.png"
              alt="AI Segmentation"
              fluid
              className="rounded grid-image"
            />
            <Image
              src="/ai/output_image.jpg"
              alt="AI Segmentation"
              fluid
              className="rounded grid-image"
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="button" onClick={handleWrite}>
            Write
          </Button>
          <Button variant="primary" type="button" onClick={exportPDF}>
            Print
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => exportToExcel(resultData)}
          >
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Results;
